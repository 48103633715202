import React from 'react';
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
// import AppFooter from '../../components/AppFooter';
// import UserListingPage from './index';
import {
  CButton,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
const UserLayout = ({children, ...props}) => {
  const navigate = useNavigate();
  const {userType = '' ,  showCreateButton = true} = props;
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader text={userType} />
        {
          showCreateButton ? (
            <div style={{ display: 'flex', padding: '20px 2.5vw', justifyContent: 'end'}}>
          <CButton
            color="primary"
            className="px-4"
            type="submit"
            style={{ border: '1px solid #0B1F3E', background: '#0B1F3E' }}
            onClick={()=> navigate('/create-user')}
          >
            Create User
          </CButton>
        </div>
          ) : ''
        }
        <div className="body flex-grow-1">
          {/* <UserListingPage /> */}
          {children}
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default UserLayout;

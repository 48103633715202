/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchDashBoardData,
  fetchExportData,
} from "../../store/reducers/userData/apiThunk";
import "./style.css";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from "@coreui/react";
import { CSmartPagination } from "@coreui/react-pro";
import { useNavigate } from "react-router-dom";
import { firstCapital } from "../../utils/helper";
import { ToastNotifySuccess } from "../../components/Toast/ToastNotify";

const limit = 10;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [userData, setUserData] = useState([]);

  const fetchDashBoardDetails = async () => {
    let requestData = {
      page: activePage,
      limit,
    };
    const response = await dispatch(fetchDashBoardData(requestData)).then(
      (val) => val
    );
    const { payload: { data = {}, status = "" } = {} } = response;
    if (status) {
      setTotalPages(data?.pagination?.pages);
      setActiveUserCount(data?.pagination?.total);
      setUserData(data.result);
    }
  };

  useEffect(() => {
    fetchDashBoardDetails();
  }, [activePage]);

  const handleExport = async () => {
    const response = await dispatch(fetchExportData()).then((val) => val);
    console.log("response: ", response);
    const { payload: { data = {}, status = "" } = {} } = response;
    if (status) {
      window.open(data.key);
      ToastNotifySuccess("Download Success");
    }
  };

  return (
    <div className="dashboard-container">
      <div className="row-container">
        <div className="col-md-12">
          <div
            className="col-md-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="section-box_user">
              <div className="fs-5">Active Users</div>
              <div className="fs-5">{activeUserCount}</div>
            </div>
            <div className="section-export-button" onClick={handleExport}>
              <div className="fs-6">Export Data</div>
            </div>
          </div>
          <div className="col-md-12">
            <CTable hover responsive className="table-wrap">
              <CTableHead className="text_style">
                <CTableRow
                  style={{
                    textTransform: "uppercase",
                    background: "#000000d4",
                  }}
                >
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    FirstName
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    LastName
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    Email
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    Country of Residence
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    Organization / Individual
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    Organization Name
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    scope="col"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  ></CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text_style" style={{ fontSize: "12px" }}>
                {userData &&
                  userData.map((item) => (
                    <CTableRow key={item._id} style={{ cursor: "pointer" }}>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {item?.firstName || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {item?.lastName || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {item?.email || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {item?.country || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {(item?.type && firstCapital(item?.type)) || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          {item?.organizationName || "-"}
                        </span>
                      </CTableDataCell>
                      <CTableDataCell
                        onClick={() =>
                          navigate(`/login-history?id=${item?._id}`)
                        }
                      >
                        <span className="value-ellipsis">
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                            style={{
                              border: "1px solid #0B1F3E",
                              background: "#0B1F3E",
                            }}
                            onClick={() =>
                              navigate(`/login-history?id=${item?._id}`)
                            }
                          >
                            Login History
                          </CButton>
                        </span>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
              </CTableBody>
            </CTable>
          </div>
          <div className="col-md-12 table-paginate">
            <CSmartPagination
              className="cursor_style"
              align="end"
              activePage={activePage}
              pages={totalPages}
              onActivePageChange={setActivePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CFormInput, CCardBody
} from '@coreui/react';
import { } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { CSmartPagination } from '@coreui/react-pro';
import { useLocation } from 'react-router-dom';
import { fetchVerificationRequests, fetchVerifyUser } from '../../store/reducers/userData/apiThunk';
import AppHeader from '../../components/AppHeader';
import AppSidebar from '../../components/AppSidebar';
import Modal from '../../components/Modal';
import "./style.scss"
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';

const limit = 10;

const VerificationRequests = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [usersData, setUsersData] = useState([]);
    const [openVerfiyModal, setOpenVerifyModal] = useState(false);
    const [commentValue, setCommentValue] = useState('');

    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    const userListing = async () => {
        const queryParams = new URLSearchParams(location.search);
        const paramId = queryParams.get('id');
        const type = queryParams.get('type');
        const requestData = {
            id: paramId,
            page: activePage,
            limit,
            type,
        }
        const response = await dispatch(fetchVerificationRequests(requestData)).then((val) => val);
        const { payload: { data: { totalDocument = '', verificationRequests = [] } = {}, status = '' } = {} } = response;
        if (status) {
            setTotalPages(Math.ceil(totalDocument / limit))
            setUsersData(verificationRequests);
        }
    }

    const handleSubmit = async() => {
        const reqData = {
            id: openVerfiyModal?.id,
            comment: commentValue,
        }
        const response = await dispatch(fetchVerifyUser(reqData)).then((val)=> val);
        const {payload : {status= "" } = {} } = response;
        if(status){
            ToastNotifySuccess("Request Approved Successfully");
            setOpenVerifyModal(false);
            userListing();
        }
    }

    const verifyModalContent = () => {
        return (
            <CCardBody>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <CFormInput type="email" id="email" placeholder={openVerfiyModal?.email} disabled />
                </div>
                <div className="mb-3">
                    <label htmlFor="comment" className="form-label">Comment:</label>
                    <textarea id="comment" className="form-control" placeholder="Enter your comment here..." rows="4" value={commentValue} onChange={(e)=> setCommentValue(e.target.value)} />
                </div>
                <CButton color="primary"
                    style={{ border: '1px solid #0B1F3E', background: '#0B1F3E' }}
                    onClick={()=> handleSubmit()}
                >Verify</CButton>
            </CCardBody>
        )
    }

    useEffect(() => {
        userListing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage]);

    useEffect(()=>{
        setCommentValue('');
    },[openVerfiyModal])

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader text={type === 'verified' ? 'Approved Requests' : "Pending Requests"} arrow={true} />
                    <div className="col-md-12 mx-auto">
                        {
                            usersData.length !== 0 ? (
                                <>
                                    <CTable hover responsive className='table-wrap'>
                                        <CTableHead className="text_style">
                                            <CTableRow style={{ textTransform: 'uppercase', background: '#000000d4' }}>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>firstName</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>lastName</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>email</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>verification Type</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>education/employment name</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>course/designation</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>other details</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Comments</CTableHeaderCell>
                                                {
                                                    type === 'pending' ? (
                                                        <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}></CTableHeaderCell>
                                                    ) : ''
                                                }
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody className="text_style" style={{ fontSize: '12px' }}>
                                            {usersData && usersData.map((item) => (
                                                <CTableRow key={item._id} style={{ cursor: 'pointer' }}>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userDetails?.firstName || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userDetails?.lastName || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userDetails?.email || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.verificationType || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{(item?.educationDetails?.length > 0 ? item?.educationDetails[0]?.institute : item?.employmentDetails[0]?.organization) || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{(item?.educationDetails?.length > 0 ? item?.educationDetails[0]?.course : item?.employmentDetails[0]?.designation) || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{(item?.educationDetails?.length > 0 ? item?.educationDetails[0]?.otherDetails : item?.employmentDetails[0]?.otherDetails) || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.requesterComments?.comment || '-'}</span></CTableDataCell>
                                                    {
                                                        type === 'pending' ? (
                                                            <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>
                                                                <CButton
                                                                    color="primary"
                                                                    className="px-4"
                                                                    type="submit"
                                                                    style={{ border: '1px solid #0B1F3E', background: '#0B1F3E' }}
                                                                    onClick={() => setOpenVerifyModal({
                                                                        email: item?.userDetails?.email,
                                                                        id: item?._id
                                                                    })}
                                                                >
                                                                    Verify Request
                                                                </CButton>
                                                            </CTableHeaderCell>
                                                        ) : ''
                                                    }
                                                </CTableRow>
                                            ))}
                                        </CTableBody>
                                    </CTable>
                                    <div className='col-md-12 table-paginate'>
                                        <CSmartPagination
                                            className="cursor_style"
                                            align="end"
                                            activePage={activePage}
                                            pages={totalPages}
                                            onActivePageChange={setActivePage}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="noDataText">
                                    No data Found
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Modal
                openDialog={openVerfiyModal}
                dialogContent={verifyModalContent()}
                closeDialog={() => setOpenVerifyModal(false)}
                heading="Verify Request"
                // closeButVisible={true}
                dialogCss="verifyModal"
            />
        </>
    );
};

export default VerificationRequests;


/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { CSmartPagination } from '@coreui/react-pro';
import { useDispatch } from 'react-redux';
import './style.css'
import { useNavigate } from 'react-router-dom';
import UserLayout from "./UserLayout"
import { fetchUserList } from '../../store/reducers/userData/apiThunk';

const limit = 10;

const UserListingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const userListing = async () => {
    const requestData = {
      page: activePage,
      type: 'individual',
      limit,
    }
    const response = await dispatch(fetchUserList(requestData));
    const { payload: { status = false, data: { updatedUsers = [], totalDocument = '' } = {} } = {} } = response;
    if (status) {
      setUsersData(updatedUsers);
      setTotalPages(Math.ceil(totalDocument/limit));
    }
  }

  useEffect(() => {
    userListing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  return (
    <>
      <UserLayout userType='Individual Users'>
        <CTable hover responsive className='table-wrap'>
          <CTableHead className="text_style">
            <CTableRow style={{ textTransform: 'uppercase', background: '#000000d4' }}>
              <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>FirstName</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>LastName</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Email</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Country of Residence</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Overall Credibility Score</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Overall Education Credibility Score</CTableHeaderCell>
              <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Overall Employment Credibility Score</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text_style" style={{ fontSize: '12px' }}>
            {usersData && usersData.map((item) => (
              <CTableRow key={item._id} style={{ cursor: 'pointer' }}>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.firstName || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.lastName || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.email || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.country || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.overallCredibleScore || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.educationScore || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.employmentScore || '-'}</span></CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>

      <div className='table-paginate'>
        <CSmartPagination
          className="cursor_style"
          align="end"
          activePage={activePage}
          pages={totalPages}
          onActivePageChange={setActivePage}
        />
      </div>
      </UserLayout>
    </>
  );
};

export default UserListingPage;


import React, { useEffect, useState } from 'react';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchLoginHistory } from '../../store/reducers/userData/apiThunk';
import moment from 'moment';
import './style.scss'
import AppHeader from '../../components/AppHeader'
import AppSidebar from '../../components/AppSidebar'


const LoginHistory = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [usersData, setUsersData] = useState([]);

    const userListing = async () => {
        const queryParams = new URLSearchParams(location.search);
        const paramId = queryParams.get('id');
        let requestData = {
            userId: paramId,
        }
        const response = await dispatch(fetchLoginHistory(requestData)).then((val) => val);
        const { payload: { data = [], status = '' } = {} } = response;
        if (status) {
            setUsersData(data);
        }
    }

    useEffect(() => {
        userListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader text={'Login History'} arrow={true} />
                    <div className="col-md-8 mx-auto">
                        <div className='my-3 fs-4'>
                            {usersData[0]?.userId?.firstName ? usersData[0]?.userId?.firstName + " " + usersData[0]?.userId?.lastName : ''}
                        </div>
                        {
                            usersData.length !== 0 ? (
                                <CTable hover responsive className='table-wrap'>
                                    <CTableHead className="text_style">
                                        <CTableRow style={{ textTransform: 'uppercase', background: '#000000d4' }}>
                                            <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Login Date</CTableHeaderCell>
                                            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Login Time</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody className="text_style" style={{ fontSize: '12px' }}>
                                        {usersData && usersData.map((item) => (
                                            <CTableRow key={item._id} style={{ cursor: 'pointer' }}>
                                                <CTableDataCell width="15%" ><span className='value-ellipsis'>{moment(item?.createdAt).format('MMMM Do YYYY')}</span></CTableDataCell>
                                                <CTableDataCell width="15%" ><span className='value-ellipsis'>{moment(item?.createdAt).format('h:mm:ss a') || '-'}</span></CTableDataCell>
                                            </CTableRow>
                                        ))}
                                    </CTableBody>
                                </CTable>
                            ) : (
                                <div className="noDataText">
                                    No data Found
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginHistory;


const lang = {
  AUTHORITY_DETAILS: 'Authorities Details',
  VERIFICATION_DETAIL: 'Verification Authorities',
  NETWORK: 'Network',
  COMMENT: 'Comment',
  ORGANIZATION: 'Organization',
  
};

export default lang;

import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import LoginPage from '../pages/login';
import DashboardPage from '../pages/dashboard/DashboardLayout';
import PrivateRoute from './privateRoute';
import UserListingPage from '../pages/users';
import OranizationUsers from '../pages/organizationUsers'
import UserDetail from '../pages/users/UserDetail';
import CreateUser from '../pages/createUser';
import LoginHistory from '../pages/loginHistory';
import EducationDetails from "../pages/educationList"
import Employment from "../pages/employmentList"
import VerificationRequests from '../pages/verificationRequests';
import WhoViewedProfile from '../pages/whoViewedProfile';
import SearchHistory from '../pages/searchHistory';
import EditEducation from '../pages/editEducationDetails';
import EditEmplyoment from '../pages/editEmploymentDetails';
import ViewedProfile from '../pages/viewedProfile';
// import ComingSoon from '../pages/comingSoon';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.INDIVIDUAL_USERS,
    element: <PrivateRoute><UserListingPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.ORGANIZATION_USERS,
    element: <PrivateRoute><OranizationUsers /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.USERS_DETAIL,
    element: <PrivateRoute><UserDetail /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.CREATE_USER,
    element: <PrivateRoute><CreateUser /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.LOGIN_HISTORY,
    element: <PrivateRoute><LoginHistory /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.EDUCATION_DETAILS,
    element: <PrivateRoute><EducationDetails /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.EMPLOYMENT_DETAILS,
    element: <PrivateRoute><Employment /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.VERIFICATION_REQUESTS,
    element: <PrivateRoute><VerificationRequests /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.WHO_VIEWED_PROFILE,
    element: <PrivateRoute><WhoViewedProfile /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.VIEWED_PROFILE,
    element: <PrivateRoute><ViewedProfile /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SEARCH_HISTORY,
    element: <PrivateRoute><SearchHistory /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.EDIT_EDUCATION_DETAILS,
    element: <PrivateRoute><EditEducation /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.EDIT_EMPLOYMENT_DETAILS,
    element: <PrivateRoute><EditEmplyoment /></PrivateRoute>,
  },
]);

export default routes;

import React from 'react';
import { CFormInput, CCol, CButton, CFormFeedback, CFormTextarea, CCardGroup, CCard, CCardBody } from "@coreui/react";
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
import { CContainer } from "@coreui/react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { validationEditEducation } from "../../utils/validations/authValidation"
import "./style.scss";
import { useDispatch } from 'react-redux';
import { fetchEditEducation } from "../../store/reducers/userData/apiThunk";
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { useLocation, useNavigate } from 'react-router-dom';

const EditEducation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initialValues = {
        courseName: state?.course,
        instituteName: state?.institute,
        yearOfQualification: state?.yearOfQualification,
        otherDetails: state?.otherDetails,
    };

    const onSubmit = async (values) => {
        const data = {
            id: state._id,
            body: {
                course: values.courseName,
                institute: values.instituteName,
                yearOfQualification: values.yearOfQualification,
                otherDetails: values.otherDetails
            },
        };
        const response = await dispatch(fetchEditEducation(data)).then((val) => val);
        const { payload: { status = '' } = {} } = response;
        if (status) {
            ToastNotifySuccess("Education Updated Successfully");
            navigate(-1);
        }
    };

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader text="Edit Education" arrow={true} />
                <div className="body flex-grow-1 px-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CCol sm="12" md="6" lg="4">
                        <div style={{ marginTop: '20px', marginBottom: '100px' }}>
                            <CContainer>
                                <div style={{ fontSize: '22px', marginBottom: '20px' }}>Edit Education Details</div>
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationEditEducation}>
                                                <Form className="row g-3">
                                                    <CCol xs={12} className='text-start'>
                                                        <label htmlFor="courseName" className="form-label">Course Name</label>
                                                        <Field type="text" name="courseName" id="courseName" placeholder="Enter Course Name" as={CFormInput} />
                                                        <ErrorMessage className="error" name="courseName" component={CFormFeedback} />
                                                    </CCol>

                                                    <CCol xs={12} className='text-start'>
                                                        <label htmlFor="instituteName" className="form-label">Institute Name</label>
                                                        <Field type="text" name="instituteName" id="instituteName" placeholder="Enter Institute Name" as={CFormInput} />
                                                        <ErrorMessage className="error" name="instituteName" component={CFormFeedback} />
                                                    </CCol>

                                                    <CCol xs={12} className='text-start'>
                                                        <label htmlFor="yearOfQualification" className="form-label">Year of Qualification</label>
                                                        <Field
                                                            type="text"
                                                            name="yearOfQualification"
                                                            id="yearOfQualification"
                                                            placeholder="Enter Year of Qualification"
                                                            as={CFormInput}
                                                        />
                                                        <ErrorMessage className="error" name="yearOfQualification" component={CFormFeedback} />
                                                    </CCol>

                                                    <CCol xs={12} className='text-start'>
                                                        <label htmlFor="otherDetails" className="form-label">Other Details</label>
                                                        <Field
                                                            id="otherDetails"
                                                            name='otherDetails'
                                                            label=""
                                                            placeholder='Enter Other Details'
                                                            rows={3}
                                                            as={CFormTextarea}
                                                        />

                                                        <ErrorMessage className="error" name="otherDetails" component={CFormFeedback} />
                                                    </CCol>

                                                    <CCol xs={12}>
                                                        <CButton type="submit" style={{ border: '1px solid #0B1F3E', background: '#0B1F3E' }}>
                                                            Save Details
                                                        </CButton>
                                                    </CCol>
                                                </Form>
                                            </Formik>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CContainer>
                        </div>
                    </CCol>
                </div>
            </div>
        </div >
    )
}

export default EditEducation;
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { CSmartPagination } from '@coreui/react-pro';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserLayout from '../users/UserLayout';
import { fetchUserList } from '../../store/reducers/userData/apiThunk';
import { firstCapital } from '../../utils/helper';

const limit = 10;

const OranizationUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const userListing = async () => {
    const requestData = {
      page: activePage,
      type: 'organization',
      limit,
    }
    const response = await dispatch(fetchUserList(requestData));
    const { payload: { status = false, data: { updatedUsers = [], totalDocument = '' } = {} } = {} } = response;
    if (status) {
      setUsersData(updatedUsers);
      setTotalPages(Math.ceil(totalDocument/limit));
    }
  }

  useEffect(() => {
    userListing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  return (
    <>
    <UserLayout userType='Organization Users'>
      <CTable hover responsive className='table-wrap'>
        <CTableHead className="text_style">
          <CTableRow style={{ textTransform: 'uppercase', background: '#000000d4' }}>
            <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>FirstName</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>LastName</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Email</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="10%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Country of Residence</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Organization Name</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Source/Client/Both</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Count of users source has verified</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold',  padding: '20px' }}>Count of users Client has viewed the profile</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="text_style" style={{ fontSize: '12px' }}>
          {usersData && usersData.map((item) => (
              <CTableRow key={item._id} style={{cursor: 'pointer'}}>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.firstName || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.lastName || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.email || '-'}</span></CTableDataCell>
                <CTableDataCell width="10%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.country || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item?.organizationName || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{(item?.subType  && firstCapital(item?.subType)) || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item.verifiedCount || '-'}</span></CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item?._id}`)}><span className='value-ellipsis'>{item.reviewedCount || '-'}</span></CTableDataCell>
              </CTableRow>
            ))}
        </CTableBody>
      </CTable>

      <div className='table-paginate'>
        <CSmartPagination
          className="cursor_style"
          align="end"
          activePage={activePage}
          pages={totalPages}
          onActivePageChange={setActivePage}
        />
      </div>
    </UserLayout>
    </>
  );
};

export default OranizationUsers;


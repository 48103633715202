import React, { useState } from 'react';
import { cilAccountLogout } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLogout } from '../../store/reducers/authentication/apiThunk';
import { ROUTE_PATH } from '../../constants/route';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
const { LANDING } = ROUTE_PATH;
const AppHeaderDropdown = () => {
  // const { token } = useSelector((state) => state.auth);
  // const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    const data = await dispatch(fetchLogout());
    if(data){
      navigate(LANDING);
    }
  };
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar size="md" style={{backgroundColor: '#0B1F3E', fontWeight:'bold'}} textColor="white">
          CC
        </CAvatar>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 pb-0" placement="bottom-end">
        <CDropdownItem href="#" >
          <div onClick={handleClickOpen} className="pt-1 pb-1">
            <CIcon icon={cilAccountLogout} className="me-2" />
            Logout
          </div>
        </CDropdownItem>
      </CDropdownMenu>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleLogout}>Yes</Button>
        </DialogActions>
      </Dialog>
    </CDropdown>
  );
};

export default AppHeaderDropdown;

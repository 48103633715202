import React from 'react'

const CrossButton = () => {
  return (
   <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.5" width="20" height="20" rx="10" fill="#7A869A"/>
<path d="M13.3332 7.16699L6.6665 13.8337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.6665 7.16699L13.3332 13.8337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default CrossButton
const ROUTE_PATH = {
  PAGE404: '*',
  LANDING: '/',
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  INDIVIDUAL_USERS: '/individual-users',
  ORGANIZATION_USERS: '/organization-users',
  USERS_DETAIL: '/users-detail',
  LOGIN_HISTORY: '/login-history',
  CREATE_USER: '/create-user',
  EDUCATION_DETAILS: '/education-details',
  EMPLOYMENT_DETAILS: '/employment-details',
  VERIFICATION_REQUESTS: '/verificationRequests',
  WHO_VIEWED_PROFILE: '/whoViewedProfile',
  VIEWED_PROFILE: '/viewedProfile',
  SEARCH_HISTORY: '/seachHistory',
  EDIT_EDUCATION_DETAILS: '/editEducationDetails',
  EDIT_EMPLOYMENT_DETAILS: '/editEmploymentDetails',
};

const API_VERSION = {
  V1: "/api/v1",
};

const API_PATH = {
  LOGIN: `${API_VERSION.V1}/admin/login`,
  LOGOUT: `${API_VERSION.V1}/admin/logout`,
  DASHBOARD: `${API_VERSION.V1}/admin/dashboard`,
  EXPORT_DATA: `${API_VERSION.V1}/admin/export-data`,
  LOGIN_HISTORY: `${API_VERSION.V1}/admin/login-history`,
  GET_USERS_LIST: `${API_VERSION.V1}/admin/users`,
  GET_USER_DETAILS: `${API_VERSION.V1}/admin/user-details`,
  GET_USER_EDUCATION_DETAILS: `${API_VERSION.V1}/admin/user-education`,
  GET_USER_EMPLOYMENT_DETAILS: `${API_VERSION.V1}/admin/user-employment`,
  GET_VERIFICATION_LISTS: `${API_VERSION.V1}/admin/request-verifications-by-status`,
  CREATE_USER: `${API_VERSION.V1}/admin/create-user`,
  GET_VIEWED_USER_PROFILE: `${API_VERSION.V1}/admin/user-profile-views`,
  GET_SEARCH_HISTORY: `${API_VERSION.V1}/admin/user-search-history`,
  VERIFY_USER: `${API_VERSION.V1}/admin/approve-verification-request`,
  EDIT_EDUCATION: `${API_VERSION.V1}/admin/education`,
  EDIT_EMPLOYMENT: `${API_VERSION.V1}/admin/employment`,
};

export { ROUTE_PATH, API_PATH };

import React, { useState } from 'react';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import SimpleBar from 'simplebar-react';
import navigation from '../_nav';
import { AppSidebarNav } from './AppSidebarNav';
import './style.css';

const AppSidebar = () => {
  const [unFoldable, setUnFoldable] = useState(true);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unFoldable}
      className="bg-sidebar"
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <div className="sidebar_heading">
          <div className="zapvid_logo" style={{fontWeight: 700}}>
            Credible Circle
          </div>
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler className="d-none d-lg-flex" onClick={() => setUnFoldable(!unFoldable)} />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);

import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import {
  fetchLogin,
  fetchLogout,
} from './apiThunk';

const initialData = {
  userData: {},
  auth: {},
};
const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: { ...initialData },
  reducers: {
    updateUser: (state, action) => {
      state.userData = action.payload;
    },
    updateAuth: (state, action) => {
      state.auth = action.payload;
    },
    logout: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.auth = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchLogout.fulfilled, (state) => (
      { ...state, userData: {}, auth: {} }));
    builder.addCase(fetchLogout.rejected, (state, action) => {
      state.auth = action.payload;
      ToastNotifyError(action.error.message);
    });
  },
});

const { reducer } = authenticationSlice;
const selectorAuthentication = (state) => state.authentication;
const {
  updateUser,
  updateAuth,
  logout,
} = authenticationSlice.actions;
export {
  updateUser,
  updateAuth,
  logout,
  selectorAuthentication,
};

export default reducer;

/* eslint-disable consistent-return */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthorization } from "../../../services/api";
import { API_PATH } from "../../../constants/route";
import { updateLoading } from "../loader";

const fetchDashBoardData = createAsyncThunk(
  "userData/dashboardData",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.DASHBOARD}?page=${data?.page}&limit=${data?.limit}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchExportData = createAsyncThunk(
  "userData/exportData",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.EXPORT_DATA}`
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchLoginHistory = createAsyncThunk(
  "userData/fetchLoginHistory",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.LOGIN_HISTORY}/${data?.userId}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchUserList = createAsyncThunk(
  "userData/fetchUserList",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_USERS_LIST}?type=${data?.type}&page=${data?.page}&limit=${data?.limit}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchUserDetails = createAsyncThunk(
  "userData/fetchUserDetails",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_USER_DETAILS}/${data.id}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchEducationLists = createAsyncThunk(
  "userData/fetchEducationLists",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_USER_EDUCATION_DETAILS}/${data.id}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchEmploymentLists = createAsyncThunk(
  "userData/fetchEmploymentLists",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_USER_EMPLOYMENT_DETAILS}/${data.id}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchVerificationRequests = createAsyncThunk(
  "userData/fetchVerificationRequests",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_VERIFICATION_LISTS}?page=${data.page}&limit=${data.limit}&type=${data.type}&userId=${data.id}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchCreateUser = createAsyncThunk(
  "userData/fetchCreateUser",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.CREATE_USER, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchViewedProfileLists = createAsyncThunk(
  "userData/fetchViewedProfileLists",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_VIEWED_USER_PROFILE}/${data.id}?page=${data.page}&limit=${data.limit}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchSearchHistoryLists = createAsyncThunk(
  "userData/fetchSearchHistoryLists",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        `${API_PATH.GET_SEARCH_HISTORY}/${data.id}?page=${data.page}&limit=${data.limit}`,
        data?.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchVerifyUser = createAsyncThunk(
  "userData/fetchVerifyUser",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.VERIFY_USER}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchEditEducation = createAsyncThunk(
  "userData/fetchEditEducation",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.patch(
        `${API_PATH.EDIT_EDUCATION}/${data.id}`,
        data.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchEditEmployment = createAsyncThunk(
  "userData/fetchEditEmployment",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.patch(
        `${API_PATH.EDIT_EMPLOYMENT}/${data.id}`,
        data.body
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

export {
  fetchDashBoardData,
  fetchExportData,
  fetchLoginHistory,
  fetchUserList,
  fetchUserDetails,
  fetchEducationLists,
  fetchEmploymentLists,
  fetchVerificationRequests,
  fetchCreateUser,
  fetchViewedProfileLists,
  fetchSearchHistoryLists,
  fetchVerifyUser,
  fetchEditEducation,
  fetchEditEmployment,
};

import React from "react";
import {
  CFormInput,
  CCol,
  CButton,
  CFormCheck,
  CFormFeedback,
  CFormTextarea,
  CCardGroup,
  CCard,
  CCardBody,
} from "@coreui/react";
import AppSidebar from "../../components/AppSidebar";
import AppHeader from "../../components/AppHeader";
import { CContainer } from "@coreui/react";
import { Formik, Field, Form, ErrorMessage } from "formik";
// import { validationEditEducation } from "../../utils/validations/authValidation"
import moment from "moment";
import "./style.scss";
import { useDispatch } from "react-redux";
// import { fetchEditEducation } from "../../store/reducers/userData/apiThunk";
import { ToastNotifySuccess } from "../../components/Toast/ToastNotify";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEditEmployment } from "../../store/reducers/userData/apiThunk";
import { useState } from "react";

const EditEmplyoment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const initialValues = {
    organization: state?.organization,
    designation: state?.designation,
    tenureStartDate: moment(state?.tenureStartDate).format("YYYY-MM-DD"),
    tenureEndDate: state?.tenureStartDate
    ? moment(state?.tenureEndDate).format("YYYY-MM-DD")
    : null,
    isCurrentlyWorking: state?.isCurrentlyWorking,
    otherDetails: state?.otherDetails,
  };

  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(
    state?.isCurrentlyWorking || false
    );
    
  const onSubmit = async (values) => {
    const data = {
      id: state._id,
      body: {
        designation: values.designation,
        isCurrentlyWorking: isCurrentlyWorking,
        organization: values.organization,
        otherDetails: values.otherDetails,
        tenureEndDate: isCurrentlyWorking ? null : values.tenureEndDate,
        tenureStartDate: values.tenureStartDate,
      },
    };
    const response = await dispatch(fetchEditEmployment(data)).then(
      (val) => val
    );
    const { payload: { status = "" } = {} } = response;
    if (status) {
      ToastNotifySuccess("Employment Updated Successfully");
      navigate(-1);
    }
  };

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader text="Edit Employment" arrow={true} />
        <div
          className="body flex-grow-1 px-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CCol sm="12" md="6" lg="4">
            <div style={{ marginTop: "20px", marginBottom: "100px" }}>
              <CContainer>
                <div style={{ fontSize: "22px", marginBottom: "20px" }}>
                  Edit Employment Details
                </div>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        <Form className="row g-3">
                          <CCol xs={12} className="text-start">
                            <label
                              htmlFor="organization"
                              className="form-label"
                            >
                              Organization Name
                            </label>
                            <Field
                              type="text"
                              name="organization"
                              id="organization"
                              placeholder="Enter Organization Name"
                              as={CFormInput}
                            />
                            <ErrorMessage
                              className="error"
                              name="organization"
                              component={CFormFeedback}
                            />
                          </CCol>

                          <CCol xs={12} className="text-start">
                            <label htmlFor="designation" className="form-label">
                              Designation Name
                            </label>
                            <Field
                              type="text"
                              name="designation"
                              id="designation"
                              placeholder="Enter Designation Name"
                              as={CFormInput}
                            />
                            <ErrorMessage
                              className="error"
                              name="designation"
                              component={CFormFeedback}
                            />
                          </CCol>

                          <CCol xs={12} className="text-start">
                            <label
                              htmlFor="tenureStartDate"
                              className="form-label"
                            >
                              Start Date
                            </label>
                            <Field
                              type="date"
                              name="tenureStartDate"
                              id="tenureStartDate"
                              placeholder="Enter Year of Qualification"
                              as={CFormInput}
                            />
                            <ErrorMessage
                              className="error"
                              name="tenureStartDate"
                              component={CFormFeedback}
                            />
                          </CCol>
                          <CCol xs={12} className="text-start">
                            <label
                              htmlFor="tenureEndDate"
                              className="form-label"
                            >
                              End Date
                            </label>
                            <Field
                              type="date"
                              name="tenureEndDate"
                              id="tenureEndDate"
                              placeholder="Enter Year of Qualification"
                              as={CFormInput}
                              disabled={isCurrentlyWorking}
                            />
                            <ErrorMessage
                              className="error"
                              name="tenureEndDate"
                              component={CFormFeedback}
                            />
                          </CCol>
                          <CCol xs={12} className="text-start">
                            <CFormCheck
                              id="isCurrentlyWorking"
                              name="isCurrentlyWorking"
                              label="Currently working in this role"
                              onChange={(e) => {
                                setIsCurrentlyWorking(e.target.checked);
                                console.log("e.target: ", e.target.checked);
                              }}
                              checked={isCurrentlyWorking}
                            />
                          </CCol>

                          <CCol xs={12} className="text-start">
                            <label
                              htmlFor="otherDetails"
                              className="form-label"
                            >
                              Other Details
                            </label>
                            <Field
                              id="otherDetails"
                              name="otherDetails"
                              label=""
                              placeholder="Enter Other Details"
                              rows={3}
                              as={CFormTextarea}
                            />

                            <ErrorMessage
                              className="error"
                              name="otherDetails"
                              component={CFormFeedback}
                            />
                          </CCol>

                          <CCol xs={12}>
                            <CButton
                              type="submit"
                              style={{
                                border: "1px solid #0B1F3E",
                                background: "#0B1F3E",
                              }}
                            >
                              Save Details
                            </CButton>
                          </CCol>
                        </Form>
                      </Formik>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CContainer>
            </div>
          </CCol>
        </div>
      </div>
    </div>
  );
};

export default EditEmplyoment;

import React from 'react';
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
// import AppFooter from '../../components/AppFooter';
import Dashboard from './index';

const DashboardLayout = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader text="DASHBOARD" />
        <div className="body flex-grow-1 px-3">
          <Dashboard />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default DashboardLayout;

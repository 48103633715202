/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEducationLists } from "../../store/reducers/userData/apiThunk";
import AppHeader from "../../components/AppHeader";
import AppSidebar from "../../components/AppSidebar";
import { ROUTE_PATH } from "../../constants/route";
import LinkSvg from "../../assets/svg/Link";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import AuthorityDetails from "../../components/authorityDetails";

const EducationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [usersData, setUsersData] = useState([]);
  const [openAuthority, setOpenAuthority] = useState(false);
  const [authorityDetails, setAuthorityDetails] = useState({});

  const userListing = async () => {
    const queryParams = new URLSearchParams(location.search);
    const paramId = queryParams.get("id");
    let requestData = {
      id: paramId,
    };
    const response = await dispatch(fetchEducationLists(requestData)).then(
      (val) => val
    );
    const { payload: { data = [], status = "" } = {} } = response;
    if (status) {
      setUsersData(data);
    }
  };

  useEffect(() => {
    userListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseAuthority = ()=>{
    setOpenAuthority(false);
  }

  const handleOpenAuthority = async (source=[], network=[])=>{
   setAuthorityDetails({source: source, network: network});
   if(source.length > 0 || network.length > 0){
      setOpenAuthority(true);
    }
  }

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader text={"Education Details"} arrow={true} />
          <div className="col-md-12 mx-auto">
            {usersData.length !== 0 ? (
              <CTable hover responsive className="table-wrap">
                <CTableHead className="text_style">
                  <CTableRow
                    style={{
                      textTransform: "uppercase",
                      background: "#000000d4",
                    }}
                  >
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Course Name
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Institute Name
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Other Details
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Year Of Qualification
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Credible Score
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Verification Level
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Verified Count
                    </CTableHeaderCell>
                    <CTableHeaderCell
                      scope="col"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "20px",
                      }}
                    >
                      Action
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody className="text_style" style={{ fontSize: "12px" }}>
                  {usersData &&
                    usersData.map((item) => (
                      <CTableRow
                        key={item._id}
                        style={{ cursor: "pointer", verticalAlign: "middle" }}
                      >
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.course || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.institute || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.otherDetails || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.yearOfQualification || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.credibleScore || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            {item?.verifiedBy.toLocaleUpperCase() || "-"}
                          </span>
                        </CTableDataCell>
                        <CTableDataCell>
                          <span className="value-ellipsis">
                            <span style={{ marginRight: "24px" }}>
                              {item?.verificationCount || 0}
                            </span>
                            <span onClick={() => handleOpenAuthority(item?.verifyBySource || [], item?.verifyByNetwork || [])}>
                              <LinkSvg />
                            </span>
                          </span>
                        </CTableDataCell>
                        <CTableDataCell
                          scope="col"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            // padding: "20px",
                          }}
                        >
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                            style={{
                              border: "1px solid #0B1F3E",
                              background: "#0B1F3E",
                            }}
                            onClick={() =>
                              navigate(ROUTE_PATH.EDIT_EDUCATION_DETAILS, {
                                state: item,
                              })
                            }
                          >
                            Edit
                          </CButton>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                </CTableBody>
              </CTable>
            ) : (
              <div className="noDataText">No data Found</div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={openAuthority}
        onClose={handleCloseAuthority}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <AuthorityDetails {...authorityDetails} handleCloseAuthority={handleCloseAuthority}/>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EducationDetails;

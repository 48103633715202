import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import StyledLoader from './StyledLoader';

function Loader() {
  // this is dummy loader change when design part ready
  return (
    <StyledLoader>
      <Backdrop
        className="backdrop"
        open
      >
        <CircularProgress
          className="loader"
        />
      </Backdrop>
    </StyledLoader>
  );
}

export default Loader;

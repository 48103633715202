import React from 'react';
import { CFormInput, CFormCheck, CCol, CButton,CFormFeedback, CRow, CCardGroup, CCard, CCardBody } from "@coreui/react";
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
import { CContainer } from "@coreui/react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {validationCreateUserSchema} from "../../utils/validations/authValidation"
import "./style.scss";
import { useDispatch } from 'react-redux';
import { fetchCreateUser } from "../../store/reducers/userData/apiThunk";
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';

const CreateUser = () => {
    const dispatch = useDispatch();
    
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        type: 'individual',
    };

    const onSubmit = async(values, {resetForm}) => {
        const data = {...values};
        const response = await dispatch(fetchCreateUser(data)).then((val)=> val);
        const {payload: {status = ''} = {} } = response;
        if(status){
            ToastNotifySuccess("User Created Successfully");
            resetForm();
        }
    };

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader text="Create User" arrow={true} />
                <div className="body flex-grow-1 px-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CCol sm="12" md="6" lg="4">
                        <div style={{ marginTop: '20px', marginBottom: '100px' }}>
                            <CContainer>
                                <div style={{ fontSize: '22px', marginBottom: '20px' }}>Create User</div>
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationCreateUserSchema}>
                                                <Form className="row g-3">
                                                    <CCol md={12}>
                                                        <Field type="text" name="firstName" placeholder="First name" aria-label="First name" as={CFormInput} />
                                                        <ErrorMessage className="error" name="firstName" component={CFormFeedback} />
                                                    </CCol>
                                                    <CCol md={12}>
                                                        <Field type="text" name="lastName" placeholder="Last name" aria-label="Last name" as={CFormInput} />
                                                        <ErrorMessage className="error" name="lastName" component={CFormFeedback} />
                                                    </CCol>
                                                    <CCol xs={12}>
                                                        <Field type="email" name="email" placeholder="Email" aria-label="Email" as={CFormInput} />
                                                        <ErrorMessage className="error" name="email" component={CFormFeedback} />
                                                    </CCol>
                                                    <CCol xs={12}>
                                                        <fieldset className="column" style={{ textAlign: 'left' }}>
                                                            <legend className="col-form-label col-sm-12 pt-0">Create User as an individual or an organization</legend>
                                                            <CRow sm={10}>
                                                                <CCol sm={6}>
                                                                    <Field type="radio" name="type" id="individual" value="individual" as={CFormCheck} label="Individual" />
                                                                </CCol>
                                                                <CCol sm={6}>
                                                                    <Field type="radio" name="type" id="organization" value="organization" as={CFormCheck} label="Organization" />
                                                                </CCol>
                                                            </CRow>
                                                        </fieldset>
                                                    </CCol>
                                                    <CCol xs={12}>
                                                        <CButton type="submit" style={{ border: '1px solid #0B1F3E', background: '#0B1F3E' }}>
                                                            Create User
                                                        </CButton>
                                                    </CCol>
                                                </Form>
                                            </Formik>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CContainer>
                        </div>
                    </CCol>
                </div>
            </div>
        </div >
    )
}

export default CreateUser;
import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';
import loader from './loader';
import userData from './userData';

const rootReducer = combineReducers({
  authentication,
  loader,
  userData,
});

export default rootReducer;

const messages = {
  MSG_VALID_EMAIL: 'Please enter a valid email',
  MSG_VALID_USERNAME: 'Please enter a valid username',
  MSG_VALID_FIRSTNAME: 'Please enter a valid first name',
  MSG_VALID_LASTNAME: 'Please enter a valid last name',
  MSG_EMAIL_REQ: 'Email is required',
  MSG_USERNAME_REQ: 'Username is required',
  MSG_FIRSTNAME_REQ: 'FirstName is required',
  MSG_LASTNAME_REQ: 'LastName is required',
  MSG_COURSENAME_REQ: 'Course Name is required',
  MSG_INSTITUTENAME_REQ: 'Institute is required',
  MSG_YEAR_REQ: 'Year Of Qualification is required',
};
export default messages;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react';
import { useDispatch } from 'react-redux';
import { CSmartPagination } from '@coreui/react-pro';
import { useLocation } from 'react-router-dom';
import { fetchViewedProfileLists } from '../../store/reducers/userData/apiThunk';
import AppHeader from '../../components/AppHeader';
import AppSidebar from '../../components/AppSidebar';

const limit = 10;

const ViewedProfile = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [usersData, setUsersData] = useState([]);

    const userListing = async () => {
        const queryParams = new URLSearchParams(location.search);
        const paramId = queryParams.get('id');
        const requestData = {
            id: paramId,
            page: activePage,
            limit,
        }
        const response = await dispatch(fetchViewedProfileLists(requestData)).then((val)=> val);
        const {payload: { data: { data = [] , pagination: { pages = 1 } = {} } = {}, status = '' } = {} } = response;
        if (status) {
            setTotalPages(pages)
            setUsersData(data);
        }
    }
    console.log("usersData", usersData)

    useEffect(() => {
        userListing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage]);

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader text={'PROFILE YOU VIEWED'} arrow={true} />
                    <div className="col-md-12 mx-auto">
                        {
                            usersData.length !== 0 ? (
                                <>
                                    <CTable hover responsive className='table-wrap'>
                                        <CTableHead className="text_style">
                                            <CTableRow style={{ textTransform: 'uppercase', background: '#000000d4' }}>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>firstName</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>lastName</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>email</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>Country of Residence</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>organization Name</CTableHeaderCell>
                                                <CTableHeaderCell scope="col" style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px' }}>user type</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody className="text_style" style={{ fontSize: '12px' }}>
                                            {usersData && usersData.map((item) => (
                                                <CTableRow key={item._id} style={{ cursor: 'pointer' }}>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.firstName || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.lastName || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.email || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.country || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.organizationName || '-'}</span></CTableDataCell>
                                                    <CTableDataCell  ><span className='value-ellipsis'>{item?.userId?.type || '-'}</span></CTableDataCell>
                                                </CTableRow>
                                            ))}
                                        </CTableBody>
                                    </CTable>
                                    <div className='col-md-12 table-paginate'>
                                        <CSmartPagination
                                            className="cursor_style"
                                            align="end"
                                            activePage={activePage}
                                            pages={totalPages}
                                            onActivePageChange={setActivePage}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="noDataText">
                                    No data Found
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewedProfile;


/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { REGEX, CONSTANTS } from '../../constants/constants';
import validationMessages from '../../constants/message';

const {
  ALLOWED_EXTENSION,
  MB_1,
} = CONSTANTS;

const {
  MSG_VALID_EMAIL,
  MSG_EMAIL_REQ,
  MSG_REQUIRED_PASSWORD,
  MSG_EMAIL_LENGTH,
  MSG_FIRSTNAME_REQ,
  MSG_LASTNAME_REQ,
  MSG_FIRSTNAME_LENGTH,
  MSG_LASTNAME_LENGTH,
  MSG_VALID_FIRSTNAME,
  MSG_VALID_LASTNAME,
  MSG_COURSENAME_REQ,
  MSG_INSTITUTENAME_REQ,
  MSG_YEAR_REQ,
} = validationMessages;

const {
  EMAIL_PATTERN, FIRSTNAME_PATTERN,
} = REGEX;
const validationSchema = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  password: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD),
});

const validationEmailSchema = yup.object({
  email: yup
    .string()
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH)
    .required(MSG_EMAIL_REQ),
});

const validationCreateUserSchema = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  firstName: yup
    .string()
    .required(MSG_FIRSTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_FIRSTNAME)
    .max(50, MSG_FIRSTNAME_LENGTH),
  lastName: yup
    .string()
    .required(MSG_LASTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_LASTNAME)
    .max(50, MSG_LASTNAME_LENGTH),
});

const validationEditEducation = yup.object({
  courseName: yup
    .string()
    .required(MSG_COURSENAME_REQ)
    .max(50, MSG_EMAIL_LENGTH),
  instituteName: yup
    .string()
    .required(MSG_INSTITUTENAME_REQ)
    .max(50, MSG_FIRSTNAME_LENGTH),
  yearOfQualification: yup
    .string()
    .required(MSG_YEAR_REQ)
    .min(4, "Minimun 4 digits")
    .max(4, "Maximum 4 digits"),
  otherDetails: yup
    .string()
    .max(250, MSG_FIRSTNAME_LENGTH),
});

const validateFileType = (extension) => {
  if (ALLOWED_EXTENSION.includes(extension.toLowerCase())) {
    return true;
  }
  return false;
};

const validateFileSize = (uploadFile) => {
  const { size = 0 } = uploadFile;
  if (size > MB_1) {
    return true;
  }
  return false;
};

export {
  validationSchema,
  validationEmailSchema,
  validationCreateUserSchema,
  validateFileType,
  validateFileSize,
  validationEditEducation
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AppHeader from '../../components/AppHeader'
import AppSidebar from '../../components/AppSidebar'
import './style.css'
import { CCard, CCardText, CButton } from '@coreui/react';
import { fetchUserDetails } from '../../store/reducers/userData/apiThunk'
import avatar from "../../assets/svg/avatar.svg";
import { ROUTE_PATH } from '../../constants/route'

const UserDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const [usersData, setUsersData] = useState([]);

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const userListing = async () => {
        const queryParams = new URLSearchParams(location.search);
        const paramId = queryParams.get('id');
        let requestData = {
            id: paramId,
        }
        const response = await dispatch(fetchUserDetails(requestData)).then((val) => val);
        const { payload: { data = [], status = '' } = {} } = response;
        if (status) {
            setUsersData(data);
        }
    }

    useEffect(() => {
        userListing();
    }, []);

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader text='User Detail' arrow={true} />
                <CCard className='mx-5 mt-5 p-4'>
                    <div className='text-start'>
                        <div className="text-start">
                            <img src={usersData?.profileImagePath ? process.env.REACT_APP_IMAGE_URL + usersData?.profileImagePath : avatar} alt="User Profile" className="img-fluid rounded-circle" style={{ width: '150px', height: '150px' }} />
                        </div>
                        <CCardText className="text-left my-4 fs-4">{`Name:- ${usersData?.firstName ? usersData?.firstName + " " + usersData?.lastName : ""}`}</CCardText>
                        <CCardText className="text-left my-4 fs-4">{`Email:- ${usersData?.email || ''}`}</CCardText>
                        {
                            usersData?.country ? (
                                <>
                                    <CCardText className="text-start my-4 fs-4">{`Country of Residence:- ${usersData?.country}`}</CCardText>
                                </>
                            ) : ''
                        }
                        <CCardText className="text-start my-4 fs-4">{`User Type:- ${usersData?.type?.charAt(0)?.toUpperCase() + usersData?.type?.slice(1) || ''}`}</CCardText>
                        {
                            usersData?.organizationName ? (
                                <>
                                    <CCardText className="text-start my-4 fs-4">{`Organization Name:- ${usersData?.organizationName}`}</CCardText>
                                </>
                            ) : ''
                        }
                        {
                            usersData.type === 'individual' ? (
                                <CCardText className="text-start my-4 fs-4">{`Overall Credible Score:- ${usersData?.overallCredibleScore || 0}`}</CCardText>
                            ) : ''
                        }
                        {
                            usersData?.type === "individual" ? (
                                <div className="text-start">
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.EDUCATION_DETAILS}/?id=${id}`)}>Education History</CButton>
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.EMPLOYMENT_DETAILS}/?id=${id}`)}>Employment History</CButton>
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.VERIFICATION_REQUESTS}/?id=${id}&type=verified`)}>Approved Requests</CButton>
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.VERIFICATION_REQUESTS}/?id=${id}&type=pending`)}>Pending Requests</CButton>
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.WHO_VIEWED_PROFILE}/?id=${id}`)}>Who viewed your profile</CButton>
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.SEARCH_HISTORY}/?id=${id}`)}>Search History</CButton>
                                </div>
                            ) : usersData?.type === "organization" ? (
                                <div className="text-start">
                                    {usersData?.subType !== "client" && 
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.VERIFICATION_REQUESTS}/?id=${id}&type=verified`)}>Approved Requests</CButton>
                                    }
                                    {usersData?.subType !== "client" && 
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.VERIFICATION_REQUESTS}/?id=${id}&type=pending`)}>Pending Requests</CButton>
                                    }
                                    {usersData?.subType !== "source" && 
                                    <CButton style={{ backgroundColor: "#0B1F3E", border: '1px solid #0B1F3E' }} className="m-2 fs-4" onClick={() => navigate(`${ROUTE_PATH.VIEWED_PROFILE}/?id=${id}`)}>Viewed Profile</CButton>
                                    }
                                </div>
                            ) : ''
                        }
                    </div>
                </CCard>
            </div>
        </div>

    )
}

export default UserDetail